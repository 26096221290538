import styled from '@emotion/styled'

import { TYPOGRAPHY_21_500 } from 'theme/theme'

export const ActionButton = styled.div`
  height: 36px;
  background: ${props => props.c ? props.c : '#646770'};
  border-radius: 5px;
  cursor: pointer;
  border: 0;
  margin: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
`

export const ActionButtonText = styled.div`
  ${TYPOGRAPHY_21_500}

  color: #fff;
  white-space: nowrap;
`
