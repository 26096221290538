import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import useDeep from 'utils/hooks/useDeep'
import Layout from 'components/layouts/homepage'
import { META } from '.config/meta'

export default function Home () {
  const [isSignedIn, setSignedIn] = useState(false)
  const router = useRouter()
  const user = useSelector((state) => state.user.value)

  useDeep(() => {
    if (user?._id) {
      setSignedIn(true)
      router.prefetch('/profile')
    } else {
      router.prefetch('/sign-in')
      router.prefetch('/sign-up-business')
      router.prefetch('/sign-up')
    }
  }, [user?._id])

  return <Layout title={META.home.title} description={META.home.description} path={META.home.path} isSignedIn={isSignedIn} />
}
