import styled from '@emotion/styled'

import { TYPOGRAPHY_18_13, RALEWAY_42 } from 'theme/theme'

export const ActionButton = styled.button`
  height: 36px;
  background: ${props => props.active ? '#0bf' : '#646770'};
  border-radius: 5px;
  cursor: pointer;
  border: 0;
  margin: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ActionButtonText = styled.div`
  ${TYPOGRAPHY_18_13}

  color: #fff;
  margin-right: ${props => props.active ? '18px' : '40px'};
  margin-left: ${props => props.active ? '18px' : '40px'};
  text-align: center;
  white-space: nowrap;

  @media (max-width: 767px) {
    margin-right: ${props => props.active ? '15px' : '35px'};
    margin-left: ${props => props.active ? '15px' : '35px'};
  }
`

export const ActionSub = styled.div`
  ${RALEWAY_42}

  color: #999ea9;

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28px;
  }
`

export const LowerBox = styled.div`
  background: linear-gradient(180deg, rgba(196, 196, 196, 0) 14.94%, rgba(196, 196, 196, 0.3) 100%);
  width: 100%;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
`
