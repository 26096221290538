import React from 'react'
import PropTypes from 'prop-types'

import Hero from 'components/layouts/homepage/hero'
import AboutUs from 'components/layouts/homepage/aboutUs'
import InvestorsAndBA from 'components/layouts/homepage/investors'
import Layout from 'components/layouts/homepage/layout'

export default function HomePage ({ isSignedIn, title, path, image, description }) {
  return (
    <Layout title={title} path={path} image={image} description={description} hero={<Hero isSignedIn={isSignedIn} />}>
      <InvestorsAndBA isSignedIn={isSignedIn} />
      <AboutUs title="About Us" />
    </Layout>
  )
}

HomePage.propTypes = {
  isSignedIn: PropTypes.bool,
  title: PropTypes.string,
  path: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string
}
