import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { LowerBox } from 'components/layouts/homepage/buttons'
import { RALEWAY_63_800, TYPOGRAPHY_24 } from 'theme/theme'
import { ActionButton, ActionButtonText } from 'components/layouts/homepage/buttonsV2'
import useMedia from 'utils/hooks/useMedia'

const HeroWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const ActionBox = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: -20px;

  @media (max-width: 767px) {
    order: 2;
    min-width: 100%;
    align-items: center;
    width: 100%;
  }
`

const ActionTitle = styled.h1`
  ${RALEWAY_63_800}

  color: #0bf;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 47px;
    text-align: center;
  }
`

const ActionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  margin-top: 20px;
`

const Text = styled.div`
  ${TYPOGRAPHY_24}

  color: #7F8289;
  max-width: 180px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: ${props => props.topFix ? props.topFix : '14px'};
`

const ColWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 40px;
`

function Hero ({ isSignedIn }) {
  const investorUrl = isSignedIn ? '/profile' : '/sign-in'
  const busUrl = isSignedIn ? '/profile' : '/sign-in-business'
  const isWide = useMedia({ minWidth: 1199 })

  return (
    <section className="pt-25">
      <HeroWrapper>
        <ActionBox>
          { isWide
            ? <ActionTitle>
                The Marketplace for Late-Stage<br />Private Tech Companies
              </ActionTitle>
            : <ActionTitle>
                The Marketplace for Late-Stage Private Tech Companies
              </ActionTitle> }

          <ActionWrapper>
            <ColWrap>
              <img src="/s/images/1.png" width="140px" height="139px" alt="Invest in the best late-stage tech companies you love" />
              <Text>Invest in the best late-stage tech companies you love</Text>
              <a href={investorUrl}><ActionButton data-test="investors">
                <ActionButtonText>
                  Investors
                </ActionButtonText>
              </ActionButton></a>
            </ColWrap>

            <ColWrap>
              <img src="/s/images/2.png" width="153px" height="145px" style={{ marginBottom: '-6px' }} alt="Fund your allocation in late-stage rounds or sell you shares" />
              <Text>Fund your allocation in late-stage rounds or sell you shares</Text>
              <a href={busUrl}><ActionButton c="#0bf" data-test="shareholders">
                <ActionButtonText>
                  Shareholders
                </ActionButtonText>
              </ActionButton></a>
            </ColWrap>

            <ColWrap>
              <img src="/s/images/3.png" width="140px" height="139px" alt="Keep your employees happy by diversifying their wealth" />
              <Text>Keep your employees happy by diversifying their wealth</Text>
              <a href={'/founders-form'}><ActionButton c="#00D6BD" data-test="founders">
                <ActionButtonText>
                  Founders
                </ActionButtonText>
              </ActionButton></a>
            </ColWrap>

            <ColWrap>
              <img src="/s/images/4.png" width="140px" height="139px" alt="We are working on solutions for you. Stay tuned!" />
              <Text>We are working on solutions for you. Stay tuned!</Text>
              <a href={'/employees-form'}><ActionButton c="#CD4AE0" data-test="shareholders">
                <ActionButtonText>
                  Employees
                </ActionButtonText>
              </ActionButton></a>
            </ColWrap>
          </ActionWrapper>
        </ActionBox>
      </HeroWrapper>
      <LowerBox />
    </section>
  )
}

Hero.propTypes = {
  isSignedIn: PropTypes.any
}

export default memo(Hero)
